import './firstSection.scss'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Typed from 'typed.js';

import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { Link } from 'react-router-dom';

import project1 from "../../../assets/projects/project-1.png";
import project2 from "../../../assets/projects/project-2.png";
import project3 from "../../../assets/projects/project-3.png";

import { motion } from 'framer-motion';
import { fadeIn } from '../../../utils/framer';





export const Contact = () => {




  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_qv3f66e', 'template_bdkowql', form.current, {
        publicKey: '8Nwqg799RN2CHaHSR',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  

  return (
    <>

      


      {/* contact me */}

      <div className=" flex lg:flex-row justify-center  flex-col p-10 lg:gap-0 gap-10 ">

        {/* image */}
        {/* <div className="">
          <div className="bg  lg:w-[50vw] lg:h-[70vh] w-full h-[40vh] bg-no-repeat bg-center bg-cover backdrop-filter"></div>
        </div> */}


        <div name="contact" className=" w-full flex flex-col justify-center items-center gap-5">

          <div className="flex flex-col items-start justify-center lg:w-[40vw]">
            <div className=" flex flex-col gap-10">
              <div className="lg:text-6xl text-3xl text-white font-bold mb-5">
                <h1>Let's build the  <span className='bg-gradient-to-r from-green-500 to-green-400  bg-clip-text text-transparent'>Future</span><span className='text-white'> of the web together!
                </span></h1>
              </div>

              <div>
                <p className='text-white opacity-40 text-xl'>&#123; </p>
                <div className="ms-5">
                  <h3 className='text-xl font-semibold text-white opacity-80 cmd-font'><span className='text-green-500  text-xl'>Phone : <br className='sm:hidden block' /></span>"+212 6073-08015",</h3>
                  <h3 className='text-xl font-semibold text-white opacity-80 cmd-font'><span className='text-green-500  text-xl'>Email : <br className='sm:hidden block' /></span>"Badirov.Faras@gmail.com",</h3>
                  <h3 className='text-xl font-semibold text-white opacity-80 cmd-font'><span className='text-green-500  text-xl'>Location : <br className='sm:hidden block' /></span>"Morocco,Casablanca",</h3>
                </div>
                <p className='text-white opacity-40 text-xl'>&#125;</p>
              </div>
            </div>

          </div>

        </div>


        <div className=" flex flex-col items-center justify-center w-full cmd-font">
          {/* <div className="App">
            <span className='font-bold text-4xl  text-white opacity-75' ref={el} />
          </div> */}

          <div className=" lg:w-[80%] w-full">

            <form className='p-5 flex flex-col gap-7 ' ref={form} onSubmit={sendEmail}>
              {/* Name */}
              <div className="inputGroup">
                <input className=' lg:h-12 h-14 p-2 border-b-2  outline-none bg-transparent text-white ' name='from_name' type="text" required />
                <label className='bg-transparent' for="name">Name</label>
              </div>

              {/* email */}
              <div className="inputGroup">
                <input className='lg:h-12 h-14 p-2 border-b-2  outline-none bg-transparent text-white ' name='user_email' type="email" required />
                <label className='bg-transparent' for="email">Email</label>
              </div>

              <div className="inputGroup">
                <textarea className='resize-none lg:h-28 h-32 p-2 border-b-2  outline-none bg-transparent text-white' name='message' required ></textarea>
                <label className='bg-transparent' for="message">Message</label>
              </div>
              {/* message */}

              {/* button */}

              <div className="flex flex-row items-center gap-10">
                <button type='submit' value="send"
                  className='lg:w-[150px]  w-[40vw] h-16 py-2 px-8 lg:text-xl text-2xl border-2 rounded-lg text-white hover:text-green-500 hover:border-green-500 hover:shadow-md hover:shadow-green-500 '>
                  SEND !
                </button>
                <div className="flex flex-row gap-3">
                  <div className="text-white text-4xl hover:text-green-500">
                    <Link>
                      <FaGithub />
                    </Link>
                  </div>
                  <div className="text-white text-4xl hover:text-green-500">
                    <Link>
                      <FaLinkedin />
                    </Link>
                  </div>
                </div>
              </div>

              {/* <input type="submit" value="Send" /> */}
            </form>

          </div>

        </div>

      </div>


    </>
  );
}
