import './firstSection.scss'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Typed from 'typed.js';

import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { Link } from 'react-router-dom';

import project1 from "../../../assets/projects/project-1.png";
import project2 from "../../../assets/projects/project-2.png";
import project3 from "../../../assets/projects/project-3.png";

import { motion } from 'framer-motion';
import { fadeIn } from '../../../utils/framer';





export const Projects = () => {




  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_qv3f66e', 'template_bdkowql', form.current, {
        publicKey: '8Nwqg799RN2CHaHSR',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };



  return (
    <>

      
      {/* cmd */}
      <div className="p-5">
        <div className="text-green-500 cmd-font text-3xl bg-black w-72 p-3 rounded-md bg-opacity-70 m-auto">
          <span className='text-blue-600'>~</span><span className='text-white'>$ cd Projects</span>
        </div>
      </div>

      {/* projects */}

      <div name="projects" className="text-xl text-green-500 cmd-font  p-10">

        <div className="bg-black w-full rounded-lg">
          <div className="flex p-2 gap-1">
            <div className="">
              <span className="bg-red-500 inline-block center w-3 h-3 rounded-full"></span>
            </div>
            <div className="circle">
              <span className="bg-yellow-500 inline-block center w-3 h-3 rounded-full"></span>
            </div>
            <div className="circle">
              <span className="bg-green-500 box inline-block center w-3 h-3 rounded-full"></span>
            </div>
          </div>

          <div className="card__content p-5">
            <p>B@F<span className='text-white'>:</span><span className='text-blue-600'>~/Projects</span><span className='text-white'>$</span><span className='ms-3 text-white '>

              {/* projects */}

              <div className="flex flex-row flex-wrap justify-evenly items-center xl:gap-0 gap-10 p-10 cmd-font">
                <div className="">
                  <p className='text-green-600 text-xl p-3 text-center font-bold'>Crypto Dashboard</p>
                  <div class="card">
                    <div class="bg-card ">
                      <img className='object-center object-cover h-full' src={project1} alt="" />
                    </div>
                    <div class="blob"></div>
                  </div>
                </div>


                <div className="">
                  <p className='text-green-600 text-xl p-3 text-center font-bold'>Social Media App</p>
                  <div class="card">
                    <div class="bg-card ">
                      <img className='object-center object-cover h-full' src={project2} alt="" />
                    </div>
                    <div class="blob"></div>
                  </div>
                </div>

                <div className="">
                  <p className='text-green-600 text-xl p-3 text-center font-bold'>E-Commerce Store</p>
                  <div class="card">
                    <div class="bg-card ">
                      <img className='object-center object-cover h-full' src={project3} alt="" />
                    </div>
                    <div class="blob"></div>
                  </div>
                </div>

              </div>

            </span></p>

          </div>


        </div>
      </div>



 


    </>
  );
}
