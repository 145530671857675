import './header.scss'

import logo from "../../src/assets/img/bflogo.png"
import { useState } from 'react';

import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

import { Link } from 'react-scroll';
import { About } from '../pages/Home/components/about';





export const Header = () => {
    const [hidden, setHidden] = useState(true)


    return (
        <>
            <nav className="flex flex-row items-center  lg:p-0 p-10 ">
                <Link className='text-white no-underline absolute lg:left-48 left-5 ' to={"/"}>
                    <img width={70} src={logo} alt="" />
                </Link>

                <div className={` cursor-pointer cmd-font text-white bg-opacity-80 text-xl flex justify-center gap-5 w-full lg:static absolute lg:flex-row flex-col lg:bg-transparent bg-black top-20 items-center right-0 lg:p-0 p-10 rounded-xl lg:block lg:flex ${hidden ? "hidden" : ""}`}>
                    <Link className='opacity-90 border-b-2  py-5 border-green-400 '>Home</Link>
                    <Link 
                    activeClass="active"
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    
                     className='cursor-pointer opacity-50 hover:opacity-90 border-b-2 border-transparent py-5 hover:border-green-400 '>About</Link>
                    <Link
                    activeClass="active"
                    to="skills"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    className='cursor-pointer opacity-50 hover:opacity-90 border-b-2 border-transparent py-5 hover:border-green-400 '>Skills</Link>
                    <Link
                    activeClass="active"
                    to="projects"
                    spy={true}
                    smooth={true}
                    offset={-50}
                     className='cursor-pointer opacity-50 hover:opacity-90 border-b-2 border-transparent py-5 hover:border-green-400 '>Projects</Link>
                    <Link
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-50}
                     className='cursor-pointer opacity-50 hover:opacity-90 border-b-2 border-transparent py-5 hover:border-green-400 '>Contact</Link>

                    <div className="flex flex-row gap-3 lg:absolute lg:right-48">
                        <div className="text-white opacity-90 text-4xl hover:text-green-500">
                            <Link>
                                <FaGithub />
                            </Link>
                        </div>
                        <div className="text-white opacity-90 text-4xl hover:text-green-500">
                            <Link to={`https://github.com/cd-badr`}>
                                <FaLinkedin />
                            </Link>
                        </div>
                    </div>

                </div>


                <label class="hamburger lg:hidden  block absolute right-5 ">
                    <input onClick={() => setHidden(!hidden)} type="checkbox" />
                    <svg viewBox="0 0 32 32">
                        <path class="line line-top-bottom" d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"></path>
                        <path class="line" d="M7 16 27 16"></path>
                    </svg>
                </label>

            </nav>

        </>
    );
}